import React from 'react'
import {
  SEO,
  Layout,
  Banner,
  Guarantee,
  Section,
  Services,
  Comments
} from '../components'

function IndexPage({ location }) {
  return (
    <Layout className="homepage">
      <SEO title='' />
      <Banner />
      <Guarantee />
      <Section />
      <Services limit={6} />
      <Comments />
    </Layout>
  )
}
export default IndexPage
